
import universal, { setHasBabelPlugin } from '/opt/build/repo/node_modules/react-universal-component/dist/index.js'


setHasBabelPlugin()

const universalOptions = {
  loading: () => null,
  error: props => {
    console.error(props.error);
    return <div>An error occurred loading this page's template. More information is available in the console.</div>;
  },
}

const t_0 = universal(import('../src/pages/404.js'), universalOptions)
const t_1 = universal(import('../src/pages/about.js'), universalOptions)
const t_2 = universal(import('../src/pages/blog.js'), universalOptions)
const t_3 = universal(import('../src/pages/components.jsx'), universalOptions)
const t_4 = universal(import('../src/pages/ComponentsSections/SectionBasics.jsx'), universalOptions)
const t_5 = universal(import('../src/pages/ComponentsSections/SectionCarousel.jsx'), universalOptions)
const t_6 = universal(import('../src/pages/ComponentsSections/SectionCompletedExamples.jsx'), universalOptions)
const t_7 = universal(import('../src/pages/ComponentsSections/SectionDownload.jsx'), universalOptions)
const t_8 = universal(import('../src/pages/ComponentsSections/SectionExamples.jsx'), universalOptions)
const t_9 = universal(import('../src/pages/ComponentsSections/SectionJavascript.jsx'), universalOptions)
const t_10 = universal(import('../src/pages/ComponentsSections/SectionLogin.jsx'), universalOptions)
const t_11 = universal(import('../src/pages/ComponentsSections/SectionNavbars.jsx'), universalOptions)
const t_12 = universal(import('../src/pages/ComponentsSections/SectionNotifications.jsx'), universalOptions)
const t_13 = universal(import('../src/pages/ComponentsSections/SectionPills.jsx'), universalOptions)
const t_14 = universal(import('../src/pages/ComponentsSections/SectionTabs.jsx'), universalOptions)
const t_15 = universal(import('../src/pages/ComponentsSections/SectionTypography.jsx'), universalOptions)
const t_16 = universal(import('../src/pages/index.js'), universalOptions)
const t_17 = universal(import('../src/pages/landing.jsx'), universalOptions)
const t_18 = universal(import('../src/pages/LandingSections/ProductSection.jsx'), universalOptions)
const t_19 = universal(import('../src/pages/LandingSections/TeamSection.jsx'), universalOptions)
const t_20 = universal(import('../src/pages/LandingSections/WorkSection.jsx'), universalOptions)
const t_21 = universal(import('../src/pages/login.jsx'), universalOptions)
const t_22 = universal(import('../src/pages/notation.jsx'), universalOptions)
const t_23 = universal(import('../src/pages/privacy.jsx'), universalOptions)
const t_24 = universal(import('../src/pages/profile.jsx'), universalOptions)
const t_25 = universal(import('../src/pages/terms.jsx'), universalOptions)


// Template Map
export default [
  t_0,
t_1,
t_2,
t_3,
t_4,
t_5,
t_6,
t_7,
t_8,
t_9,
t_10,
t_11,
t_12,
t_13,
t_14,
t_15,
t_16,
t_17,
t_18,
t_19,
t_20,
t_21,
t_22,
t_23,
t_24,
t_25
]
